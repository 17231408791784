defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Components/EurojackpotClassic',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotDictionary',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotInfo',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Components/GameClients/EurojackpotClassicClient'
  ],
  function (Component, EurojackpotDictionary, EurojackpotInfo, EurojackpotClassicClient) {

    // Component:
    Component('eurojackpot-classic', [EurojackpotDictionary, EurojackpotInfo], function (m, route, settings, property) {

      // Feed check:
      if (EurojackpotInfo.isFeedDown() || EurojackpotInfo.noOpenDraw()) {
        if (!window.sitecoreEnv && location.search.indexOf('avoid_redirect') === -1) {
          location.href = '/eurojackpot/notavailable';
        }

        return;
      }

      // Components:
      var client = EurojackpotClassicClient(m, settings, property);

      // Routes:
      route('/', client);

    });

  });
