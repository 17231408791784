defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Templates/RowStarNumber',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril'
  ],
  function (m) {

    // Template:
    var RowStarNumber = function (starNumber, className, config) {

      // Variables:
      className = className || '';

      // Return:
      return m('span', { class: 'star-box star-box-' + starNumber + className, config: config }, [
        m('svg', { class: 'icon icon-star main-outline' }, [
          m('use', { href: '/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons.svg#icon-star-outline' })
        ]),
        m('span', { class: 'icon icon-star gold' }),
        function () {
          if (className.indexOf('selected') === -1) return null;

          var effectStars = [];
          for (var outlineIndex = 1; outlineIndex < 4; outlineIndex++) {
            effectStars.push(m('svg', { class: 'icon icon-star outline-' + outlineIndex }, [
              m('use', { href: '/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons.svg#icon-star-outline' })
            ]));
          }
          for (var smallStarIndex = 1; smallStarIndex < 14; smallStarIndex++) {
            effectStars.push(m('svg', { class: 'icon icon-small-star icon-small-star--' + smallStarIndex }, [
              m('use', { href: '/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons.svg#icon-star-gold' })
            ]));
          }
          return effectStars;
        }(),
        m('span', { class: 'number' }, starNumber)
      ]);

    };

    // Public functions:
    return RowStarNumber;

  });
