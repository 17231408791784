defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Templates/Subnavigation',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
    'Common/Framework/EventHandling/Scripts/OutsideClick'
  ],
  function (m, Render, OutsideClick) {

    // View:
    var Subnavigation = function (controller) {

      // Variables:
      var links = controller.subnavigation();
      var render = Render();
      var playType = controller.game().playType();
      var playTypeOptions = {
        Classic: 'lg Selv', // = V�lg Selv
        System: 'System',
        Lucky: 'Lykke',
        Lightning: 'Lyn'
      };

      // Functions:
      var title = function () {
        if (links && links.length > 0) {
          var sortedLinks = links.sort(function (a, b) {
            return b.linkUrl.length - a.linkUrl.length;
          });

          for (var i = 0; i < sortedLinks.length; i++) {
            if (sortedLinks[i].title.indexOf(playTypeOptions[playType]) > -1) {
              return sortedLinks[i].title;
            }
          }
        }
      };

      // Render:
      render.ready.then(function (nodes) {
        var subnavigation = nodes['subnavigation'];

        if (!subnavigation.rendered && nodes['options']) {
          var $options = nodes['options'].$element;
          var $subnavigation = subnavigation.$element;
          var button = nodes['button'];
          var itemHeight = 0;
          var items = nodes['item'];

          for (var i = 0; i < items.length; i++) {
            itemHeight += $DS(items[i].$element).outerHeight();
          }

          button.$element.addEventListener('click', function () { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            button.context.toggled = !button.context.toggled;

            $options.style = button.context.toggled ? 'max-height: ' + itemHeight + 'px;' : '';
            $subnavigation.className = 'eurojackpot-games-subnavigation' + (button.context.toggled ? ' active' : '');
          });

          OutsideClick(subnavigation.$element, subnavigation.context, function () {
            if (button.context.toggled) {
              button.context.toggled = false;

              $options.style = '';
              $subnavigation.className = 'eurojackpot-games-subnavigation';
            }
          });
        }
      });

      // View:
      return m('div', { class: 'eurojackpot-games-subnavigation-bar' }, [
        m('div', { class: 'eurojackpot-games-subnavigation', config: render.depend('subnavigation') }, [
          m('h1', { class: 'subnavigation-placeholder', config: render.depend('button') }, [
            title(),
            m('span', { class: 'subnavigation-arrow' }, [
              m('svg', { class: 'icon' }, [
                m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-down-arrow' })
              ])
            ])
          ]),
          links && links.length > 0 ? m('ul', { class: 'subnavigation-options', config: render.depend('options') }, links.map(function (link) {
            if (link.title !== title()) {
              return m('li', { class: 'item', config: render.depend('item') }, [
                m('a', { href: link.linkUrl }, link.title)
              ]);
            }
          })) : null
        ]),
        m('a', { class: 'eurojackpot-information-button', href: '#how-to-play' })
      ]);

    };

    // Public functions:
    return Subnavigation;

  });
