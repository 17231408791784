defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Components/EurojackpotWinningNumbers',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/SelectDropdown',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotApi',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotDictionary',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotUtils',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Templates/EurojackpotWinningNumbers/Drawing',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/FeaturedWinnersApi',
  ],
  function (Component, Utils, SelectDropdown, EurojackpotApi, EurojackpotDictionary, EurojackpotUtils, Drawing, FeaturedWinnersApi) {

    // Component:
    Component('eurojackpot-winning-numbers', [EurojackpotDictionary], function (m, route, settings) {

      // Components:
      var root = {
        controller: function () {

          // Variables:
          this.bonusNumbers = m.prop([]);
          this.d = EurojackpotDictionary.get;
          this.eurojackpotDrawDate = m.prop();
          this.eurojackpotDrawDates = m.prop([]);
          this.jokerDisabled = m.prop(settings.jokerClassicEnabled === false && settings.jokerLightningEnabled === false && settings.jokerLuckyEnabled === false && settings.jokerSystemEnabled === false); // Note: we explicitly check for falsity here to avoid undefined or null checks coming through.
          this.jokerDrawDate = m.prop();
          this.jokerDrawDates = m.prop([]);
          this.jokerDrawType = m.prop();
          this.highestEurojackpotPrize = 0;
          this.highestJokerPrize = 0;
          this.loading = m.prop(true);
          this.prizes = m.prop([{ amount: 0 }]);
          this.prizesJoker = m.prop([]);
          this.winningNumbers = m.prop([]);
          this.winningNumbersJoker = m.prop([]);
          this.featuredWinners = m.prop([]);
          this.featuredWinnersJoker = m.prop([]);
          this.game = m.prop('EurojackpotFriday');
          this.jokerGame = m.prop('JokerSaturday');
          this.gameDate = m.prop();
          this.jokerGameDate = m.prop();

          // Functions:
          this.getEurojackpotWinningNumbers = function (selectWeek, selectYear) {
            var self = this;

            if (selectWeek && selectYear) {
              var matchingDate = Utils.getMatchingDateFromWeekAndYear(selectWeek, selectYear, self.eurojackpotDrawDates(), 'draw');
              if (matchingDate) self.eurojackpotDrawDate(matchingDate);
            }

            EurojackpotApi.getEurojackpotWinningNumbers({ drawId: this.eurojackpotDrawDate().value }).then(function (data) {
              self.winningNumbers(data.winningNumbers);
              self.bonusNumbers(data.starNumbers);
              self.prizes(data.prizes);
              self.highestPrize = self.getHighestAmount();
              self.gameDate(data.date);
            }).then(function () {
              m.redraw();

              FeaturedWinnersApi.getFeaturedWinners({ date: self.gameDate(), game: self.game() }).then(function (data) {
                self.featuredWinners(data);
                m.redraw();
              });
            });


          }.bind(this);

          this.getHighestAmount = function () {
            var prizesArray = this.prizesFiltered();
            var highest = 0;

            for (var i = 0; i < prizesArray.length; i++) {
              if (highest < prizesArray[i].amount) {
                highest = parseInt(prizesArray[i].amount, 10);
              }
            }

            return highest;
          };

          this.getJokerWinningNumbers = function () {
            var self = this;

            EurojackpotApi.getJokerWinningNumbers({ drawId: this.jokerDrawDate().value, jokerType: this.jokerDrawDate().type }).then(function (data) {
              self.winningNumbersJoker(data.winningNumbers);
              self.prizesJoker(data.prizes);
              self.jokerDrawType(data.jokerType);
              self.highestJokerPrize = self.getHighestAmount();
              self.jokerGameDate(data.date);
            }).then(function () {
              m.redraw();

              FeaturedWinnersApi.getFeaturedWinners({ date: self.jokerGameDate(), game: self.jokerGame() }).then(function (data) {
                self.featuredWinnersJoker(data);
                m.redraw();
              });
            });
          }.bind(this);

          this.prizesFiltered = function () {
            var prizes = this.prizes();
            var prizeArray = [];
            var selectedWinningNumbers;
            var self = this;

            if (settings.selectedWinningNumbers) {
              selectedWinningNumbers = settings.selectedWinningNumbers.split(',');
            } else {
              return this.prizes();
            }

            var neighbors = [-1, 1, 0];

            selectedWinningNumbers.forEach((id) => {
              prizes.forEach((value) => {
                if (value.id && value.id == id) {
                  var scIdName = self.d('WinningNumbers/CorrectNumbers/Id' + value.id);
                  // we want to make sure the sitecore-id-name is mapped to the correct prize
                  // For eurojackpot v2 prizes id6 and id7 swapped order, but the winningNumbers module does not know
                  // gameVersionNo of a particular draw. Therefore it checks neighbors if something seems off
                  for (var i = 0; i < neighbors.length; i++) {
                    if (value.name.indexOf(scIdName) >= 0) {
                      break;
                    }
                    scIdName = self.d('WinningNumbers/CorrectNumbers/Id' + (value.id + neighbors[i]));
                  }
                  value.name = scIdName + ' ' + self.d('WinningNumbers/CorrectNumbers/CorrectNumber');

                  prizeArray.push(value);
                }
              });
            });

            return prizeArray.length > 0 ? prizeArray : this.prizes();
          }.bind(this);

          this.jokerFiltered = function () {
            var jokerArray = [];
            var prizesJoker = this.prizesJoker();
            var self = this;

            prizesJoker.forEach((value) => {
              value.name = self.d('JokerWinningNumbers/CorrectJokerNumbers/Id' + value.id) + ' ' + self.d('JokerWinningNumbers/CorrectJokerNumbers/CorrectNumber');
              jokerArray.push(value);
            });

            return (jokerArray.length > 0) ? jokerArray : this.prizesJoker();
          }.bind(this);

          this.selectEurojackpotDate = function (date) {
            this.eurojackpotDrawDate(date);
            this.getEurojackpotWinningNumbers();
            Utils.setWeekAndYearToHash(date.draw);
          }.bind(this);

          this.selectJokerDate = function (date) {
            this.jokerDrawDate(date);

            this.getJokerWinningNumbers();
          }.bind(this);

          this.winningNumberPage = function () {
            var winningNumbers = /vindertal/.test(window.location);

            if (winningNumbers) {
              var $element = document.querySelector('#eurojackpot-winning-number-subheader');
              if (this.highestPrize > 0) {
                $element.textContent = this.d('WinningNumbers/PrizePostfix');
              } else {
                $element.style.display = 'none';
              }
            }

            return winningNumbers ? 'hidden' : '';
          }.bind(this);

          this.isWinningNumbersReady = function () {
          // Returns true if selected draw is loaded and have winningNumbers
            return !this.loading() && this.winningNumbers() && this.winningNumbers().length > 0;
          }.bind(this);

          this.isJokerWinningNumbersReady = function () {
            // Returns true if selected draw is loaded and have winningNumbers
            return !this.loading() && this.winningNumbersJoker() && this.winningNumbersJoker().length > 0;
          }.bind(this);

          // Setup:
          EurojackpotApi.getCompletedDrawDates().then(function (draws) {
            var formattedDates = {};

            for (var key in draws) {
              if (draws.hasOwnProperty(key)) {
                var formattedDatesValue = [];

                for (var i = 0; i < draws[key].drawDates.length; i++) {
                  var draw = draws[key].drawDates[i];

                  if (key === 'joker') {
                    formattedDatesValue.push({
                      name: this.d('JokerWinningNumbers/DateOptionPrefix') + ' ' + EurojackpotUtils.formatISO8601(draw + ' 00:00:00', { includeTime: false, includeDayName: true, dayNameSeparatorFromDate: this.d('JokerWinningNumbers/DayNameSeparatorFromDate') }),
                      value: draws[key].draws[i].drawNo,
                      type: draws[key].draws[i].jokerType
                    });
                  } else {
                    formattedDatesValue.push({
                      name: this.d('WinningNumbers/DateOptionPrefix') + ' ' + EurojackpotUtils.formatISO8601(draw + ' 00:00:00', { includeTime: false }),
                      value: draws[key].draws[i].drawNo,
                      draw: draw
                    });
                  }
                }

                formattedDates[key] = formattedDatesValue;
              }
            }

            this.eurojackpotDrawDate(formattedDates['eurojackpot'][0]);
            this.eurojackpotDrawDates(formattedDates['eurojackpot']);
            this.jokerDrawDate(formattedDates['joker'][0]);
            this.jokerDrawDates(formattedDates['joker']);

            // check to see if we have week and date in hash, to show this.
            if (history.pushState) {
              var weekAndYear = Utils.getWeekAndYearFromHash();
              if (weekAndYear) {
                this.getEurojackpotWinningNumbers(weekAndYear.week, weekAndYear.year);
              } else {
                this.getEurojackpotWinningNumbers();
              }
            } else {
              this.getEurojackpotWinningNumbers();
            }
            this.getJokerWinningNumbers();

            this.loading(false);
          }.bind(this));

        },

        view: function (controller) {
          return m('section', { class: 'eurojackpot-section' }, [
            m('a', { class: 'anchor', name: 'eurojackpotwinningnumbersspot1' }),
            // Eurojackpot section
            m('div', { class: 'eurojackpot-content-wrapper' }, [
              m('h2', { class: 'headline ' + controller.winningNumberPage() }, controller.d('WinningNumbers/PrizeHeading')),
              m('p', { class: 'subheadline ' + controller.winningNumberPage() }, controller.highestPrize > 0
                ? controller.d('WinningNumbers/PrizePostfix') + '.'
              // Amount should temporarily be hidden according to #IU-8152
              // + ' ' + EurojackpotUtils.formatCurrency(controller.highestPrize)
                : ''),
              m('div', { class: 'select-date-section' }, [
                SelectDropdown(controller, controller.eurojackpotDrawDates, controller.eurojackpotDrawDate, controller.selectEurojackpotDate)
              ]),

              function () {
                if (controller.loading()) {
                  return null;
                }

                if (!controller.isWinningNumbersReady()) {
                  return m('div', { class: 'disclaimer-info-text' }, controller.d('WinningNumbers/WinningNumbersNotReady'));
                }

                return [
                  m('div', { class: 'winning-numbers-list' }, Drawing(controller, {
                    winningNumbers: controller.winningNumbers(),
                    bonusNumbers: controller.bonusNumbers(),
                    prizes: controller.prizesFiltered(),
                    winners: controller.featuredWinners()
                  }, 'eurojackpot'))
                ];
              }()
            ]),

            // Joker section
            !controller.jokerDisabled() ? m('div', { class: 'eurojackpot-content-wrapper joker-section' }, [
              // m('h2', { class: 'headline ' + controller.winningNumberPage() }, controller.d('JokerWinningNumbers/PrizeHeading')),
              m('div', { class: 'logo' }, [
                m('svg', { class: 'joker-hat' }, [
                  m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#icon-joker-hat' })
                ]),
                m('svg', { class: 'joker-logo' }, [
                  m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#icon-joker-text-logo' })
                ]),
                // m('span', { class: 'joker-text' }, controller.jokerDrawType() === 'JokerWednesday' ? controller.d('JokerWinningNumbers/WednesdayJokerName') : controller.d('JokerWinningNumbers/SaturdayJokerName'))
              ]),
              m('p', { class: 'subheadline ' }, controller.d('JokerWinningNumbers/PrizePostfix')),
              m('div', { class: 'select-date-section' }, [
                SelectDropdown(controller, controller.jokerDrawDates, controller.jokerDrawDate, controller.selectJokerDate)
              ]),
              (function () {
                if (controller.loading()) {
                  return '';
                }

                if (!controller.isJokerWinningNumbersReady()) {
                  return m('div', { class: 'disclaimer-info-text' }, controller.d('JokerWinningNumbers/WinningNumbersNotReady'));
                }

                return [
                  m('div', { class: 'winning-numbers-list' }, Drawing(controller, {
                    winningNumbers: controller.winningNumbersJoker(),
                    prizes: controller.jokerFiltered(),
                    jokerType: controller.jokerDrawType(),
                    winners: controller.featuredWinnersJoker()
                  }, 'joker'))
                ];
              })()
            ]) : null
          ]);
        }
      };

      // Setup routes:
      route('/', root);

    });

  });
