defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Templates/RowStarNumbers',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Templates/RowStarNumber'
  ],
  function (m, RowStarNumber) {

    // Context:
    var RowStarNumbers = function (controller, rowNumber) {

      // Variables:
      var currentRow = controller.property('currentRow');
      var dom = [];
      var game = controller.game();
      var starNumber = 0;
      var totalStarNumbers = game.starNumbersIntervalMax();

      // Functions:
      var starNumberConfig = function (starNumber) {
        if (rowNumber + 1 === currentRow) {
          return function ($element, rendered, context) {
            if (rendered && context.rowNumber === rowNumber) {
              return false;
            }

            context.rowNumber = rowNumber;

            $element = $DS($element);

            $element.unbind('click touchend touchmove touchstart');

            var touchClick = false;
            var touchEnd = false;
            var touchMove = false;
            var touchTimer = null;

            $element.on('click', function () {
              if (!touchClick) {
                starNumberToggle(starNumber);
              }
            });

            $element.on('touchstart', function () {
              clearTimeout(touchTimer);

              touchClick = false;
              touchEnd = false;
              touchMove = false;

              touchTimer = setTimeout(function () {
                if (!touchEnd && !touchMove) {
                  touchClick = true;

                  $element.addClass('active');
                }
              }, 250);
            });

            $element.on('touchend', function () {
              touchEnd = true;

              if (touchClick) {
                $element.removeClass('active');

                starNumberToggle(starNumber);

                m.redraw();
              }
            });

            $element.on('touchmove', function () {
              touchMove = true;
            });

            $element.on('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function () {
              controller.property('starNumberSelected' + starNumber, false);
            });
          };
        }
      };

      var starNumberToggle = function (starNumber) {
        var hasStarNumber = game.hasStarNumber(currentRow, starNumber);
        var nextRow = game.getRow(currentRow + 1);
        var starNumbers = game.getRow(currentRow).starNumbers.length;
        var totalNumbers = starNumbers + game.getRow(currentRow).numbers.length;

        if (starNumbers < game.starNumbersPerRowMax() && totalNumbers < game.totalNumbersPerRowMax() && !hasStarNumber) {
          game.addStarNumber(currentRow, starNumber);

          if (game.isRowValid(currentRow)) {
            controller.property('animate', 'row-completed');

            if (!nextRow) {
              game.addRow();
            }
          }
        } else if (hasStarNumber) {
          game.removeStarNumber(currentRow, starNumber);

          var lastRow = game.getRow(game.rows().length);

          if (nextRow.count === lastRow.count) {
            lastRow = null;
          }

          if (!game.isRowValid(currentRow) && nextRow && nextRow.numbers.length === 0 && nextRow.starNumbers.length === 0 && lastRow && lastRow.numbers.length === 0 && lastRow.starNumbers.length === 0) {
            game.removeRow(currentRow + 2);
          }

          if (controller.property('animate') === 'row-completed') {
            controller.property('animate', '');
          }
        } else {
          controller.property('animate', 'shake' + (game.playType() === 'Lucky' ? '' : '-star-numbers'));
        }

        if (controller.property('animate') !== 'shake' + (game.playType() === 'Lucky' ? '' : '-star-numbers')) {
          controller.property('starNumberSelected' + starNumber, true);
        }

        m.redraw();
      };

      // View:
      while (starNumber < totalStarNumbers) {
        starNumber++;

        var className = '';
        var hasStarNumber = game.hasStarNumber(rowNumber + 1, starNumber);

        if (hasStarNumber) {
          className = className + ' selected';
        }

        if (controller.property('starNumberSelected' + starNumber) && rowNumber + 1 === currentRow) {
          className = className + ' flip-animation-in-progress ' + (hasStarNumber ? '' : 'de') + 'select-number-animation';
        }

        dom.push(RowStarNumber(starNumber, className, starNumberConfig(starNumber)));
      }

      return [
        m('div', { class: 'row-stars-container clearfix' }, dom)
      ];
    };

    // Public functions:
    return RowStarNumbers;

  });
