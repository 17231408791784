defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Components/GameClients/EurojackpotClassicClient', [
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
  'Shared/Framework/Mithril/Scripts/Helpers/Storage',
  'Common/Framework/EventHandling/Scripts/Event',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Classic/Classic',
  'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
  'DanskeSpil/Framework/NumberGames/Scripts/Templates/JokerOverlay',
  'DanskeSpil/Framework/NumberGames/Scripts/Templates/SubscriptionValidationOverlay',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/StatisticsComponentForGameClient',
  'DanskeSpil/Domain/Eurojackpot/Scripts/Models/EurojackpotGame',
  'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotDictionary',
  'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotInfo',
  'DanskeSpil/Domain/Eurojackpot/Scripts/Templates/EurojackpotClassic/DeleteOverlay',
  'DanskeSpil/Domain/Eurojackpot/Scripts/Templates/EurojackpotClassic/Rows',
  'DanskeSpil/Domain/Eurojackpot/Scripts/Templates/PurchaseBar',
  'DanskeSpil/Domain/Eurojackpot/Scripts/Templates/Subnavigation',
  'DanskeSpil/Domain/Eurojackpot/Scripts/Templates/ShakeOverlay',
  'DanskeSpil/Domain/NumberGames/Scripts/Templates/NotificationBox'
], function (Params, Storage, Event, NumberGamesClassic, Overlay, JokerOverlay, SubscriptionValidationOverlay, StatisticsComponentForGameClient, EurojackpotGame, EurojackpotDictionary, EurojackpotInfo, DeleteOverlay, Rows, PurchaseBar, Subnavigation, ShakeOverlay, NotificationBox) {

  // Client:
  var GameClient = function (m, settings, property) {

    // Component:
    var root = {
      controller: function () {
        this.settings = settings;
        this.property = property;

        // Variables:
        this.gameInstanceId = m.prop(Params.extract('gameInstanceId'));
        this.game = m.prop(EurojackpotGame.setupGame({
          gameInstanceId: this.gameInstanceId(),
          playType: 'Classic',
          verticalType: settings.verticalType,
          manualGameVersionNo: settings.manualGameVersionNo
        }));
        this.d = EurojackpotDictionary.get;
        this.deleteOverlay = m.prop(new Overlay(DeleteOverlay(this.d)));
        this.hasDisplayedCreateNewRowHelp = m.prop(Storage.get('eurojackpot-hasDisplayedCreateNewRowHelp'));
        this.jokerEnabled = m.prop(settings.jokerEnabled);
        this.jokerOverlay = m.prop(new Overlay(JokerOverlay(this.game(), 'eurojackpot-classic-game-overlay', this.d, EurojackpotInfo.jokerData())));
        this.pageReload = m.prop(typeof this.gameInstanceId() !== 'undefined');
        this.property = property;
        this.property('currentRow', 1);
        this.subnavigation = m.prop(settings.quickLinks);
        this.subscriptionValidationOverlay = m.prop(new Overlay(SubscriptionValidationOverlay('eurojackpot-classic-game-overlay subscription-validation-overlay', {
          header: this.d('SubscriptionValidationOverlay/Header'),
          body: this.d('SubscriptionValidationOverlay/Body'),
          dismiss: this.d('SubscriptionValidationOverlay/Dismiss')
        })));

        // Functions:
        this.dontDisplayCreateNewRowHelpAgain = function () {
          this.hasDisplayedCreateNewRowHelp(true);

          Storage.set('eurojackpot-hasDisplayedCreateNewRowHelp', true);
        }.bind(this);

        this.purchase = function () {
          var game = this.game();

          if (game.isGameValid()) {

            // Abort purchase and show subscription validation overlay, if subscription is chosen but not valid
            if (game.numberOfDraws() === 0 && !game.isSubscriptionValid()) {
              this.subscriptionValidationOverlay().show();

              return;
            }

            var deferred = m.deferred();
            var self = this;

            if (this.jokerEnabled()) {
              this.jokerOverlay().show(deferred.resolve);
            } else {
              deferred.resolve();
            }

            deferred.promise.then(function () {
              game.prepareConfirm().then(function (confirmUrl) {
                if (game.numberOfDraws() === 0) {
                  game.sendToSubscription();
                } else {
                  location.href = confirmUrl;
                }
              }, function () {
                self.errorOverlay().show();
              });
            });
          }
        }.bind(this);

        // Replace state:
        if (!this.gameInstanceId() || this.gameInstanceId() !== this.game().id()) {
          Params.set('gameInstanceId=' + this.game().id());
        }

        // Tracking - Push productDetails event to dataLayer
        this.game().trackingProductDetails();

        Event.subscribe('eurojackpot:classic:createNewRow', () => this.property('callToCreateNewRow', true));
      },

      view: function (controller) {
        if (settings.useNewNumbersPicker) {
          return (<NumberGamesClassic
            controller={controller}
            gameModel={EurojackpotGame}
            gameName={'eurojackpot'}
            rowsOptions={EurojackpotInfo.getClassicOptions()}
            defaultRowCount={EurojackpotInfo.getClassicDefaultRowCount()}
          />);
        }

        return m('div', { class: 'eurojackpot-classic-game' }, [
          ShakeOverlay(controller),
          !settings.subscriptionsMode ? Subnavigation(controller) : null,
          StatisticsComponentForGameClient(controller, 'Eurojackpot'),
          m('div', { class: 'eurojackpot-section eurojackpot-classic-game-section purchase-bar-container' }, [
            m('div', { class: 'eurojackpot-content-wrapper' }, [
              Rows(controller),
              !settings.subscriptionsMode ? [
                NotificationBox('ejp_normal', settings.notifications),
                !controller.property('isMultiClient') ? PurchaseBar(controller) : null
              ] : null
            ])
          ])
        ]);
      }
    };

    return root;
  };

  // Public functions:
  return GameClient;

});
