defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Components/EurojackpotGameClosed',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotDictionary',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotInfo'
  ],
  function (Component, EurojackpotDictionary, EurojackpotInfo) {

    // Component:
    Component('eurojackpot-game-closed', [EurojackpotDictionary, EurojackpotInfo], function (m, route, settings) {

      // Feed check:
      if (!EurojackpotInfo.isFeedDown() && !EurojackpotInfo.noOpenDraw()) {
        location.href = '/eurojackpot';
        return;
      }

      // Components:
      var root = {
        controller: function () {
          this.d = EurojackpotDictionary.get;
        },

        view: function (controller) {
          return m('div', { class: 'eurojackpot-no-open-draw' }, [
            m('section', { class: 'eurojackpot-section' + (settings.topManchete || settings.bottomManchete ? ' manchete' : '') + (settings.topManchete ? ' top' : '') + (settings.bottomManchete ? ' bot' : '') }, [
              m('div', { class: 'eurojackpot-content-wrapper' }, [
                EurojackpotInfo.isFeedDown() ? [
                  m('h2', { class: 'no-open-draw-text' }, controller.d('EurojackpotClosed/GameNotAvailableHeader')),
                  m('h2', { class: 'no-open-draw-text' }, controller.d('EurojackpotClosed/GameNotAvailableText'))
                ] : [
                  m('h2', { class: 'no-open-draw-text' }, controller.d('EurojackpotClosed/NoOpenDrawHeader')),
                  m('h2', { class: 'no-open-draw-text' }, controller.d('EurojackpotClosed/NoOpenDrawText'))
                ]
              ])
            ])
          ]);
        }

      };

      // Setup routes:
      route('/', root);

    });

  });
