defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Templates/EurojackpotSystem/KeyTab',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotApi',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Templates/EurojackpotSystem/Accordion'
  ],
  function (m, EurojackpotApi, Accordion) {

    // Template:
    var KeyTab = function (controller) {

      // Variables:
      var d = controller.d;
      var game = controller.game();
      var generatedRows = game.generatedRows();
      var isGameValid = game.isGameValid();
      var system = game.systemName();
      var systemRows = controller.property('systemRows');

      if (isGameValid && generatedRows.length === 0) {
        game.gameGenerateRows().then(function () {
          m.redraw();
        });
      }

      if (!systemRows || systemRows.name !== system) {
        EurojackpotApi.getSystemKeys(system).then(function (response) {
          controller.property('systemRows', { name: system, rows: response });

          m.redraw();
        });
      }

      // Functions:
      var print = function () {
        controller.print(true);
      };

      // View:
      return m('div', { class: 'inner-wrapper' }, [
        m('h2', { class: 'tab-headline' }, d('EurojackpotSystem/KeyTab/Header', { systemName: system })),
        m('p', isGameValid && generatedRows.length > 0 ? d('EurojackpotSystem/KeyTab/SubheaderNumbersPicked') : d('EurojackpotSystem/KeyTab/Subheader')),
        m('div', { class: 'accordions-container' }, [
          m('div', { class: 'print-button', onclick: print }, [
            m('svg', { class: 'icon-print' }, [
              m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-print' })
            ])
          ]),
          m('div', { class: 'accordions-header' }, system),
          function () {
            var chunks = [];
            var breakAt = 50;
            var dom = [];
            var rows = ((isGameValid && generatedRows.length > 0) || !controller.property('systemRows')) ? generatedRows : controller.property('systemRows').rows;

            for (var i = 0; i < rows.length; i++) {
              var chunkNumber = Math.floor(i / breakAt);

              if (typeof chunks[chunkNumber] === 'undefined') {
                chunks[chunkNumber] = [];
              }

              chunks[chunkNumber].push(rows[i]);
            }

            for (var j = 0; j < chunks.length; j++) {
              dom.push(Accordion(controller, 'key-tab', { rows: chunks[j], index: j }));
            }

            return dom;
          }()
        ])
      ]);

    };

    // Public functions:
    return KeyTab;

  });
