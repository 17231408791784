defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Templates/EurojackpotLucky/OptionSelector',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Resize'
  ],
  function (m, Render, Resize) {

    // Template:
    var OptionSelector = function (controller) {

      // Variables:
      var $container = null;
      var $innerWrapper = null;
      var $optionsContainer = null;
      var $elements = [];
      var d = controller.d;
      var game = controller.game();
      var render = Render();
      var rows = controller.rowsOptions();
      var rowsToGenerate = game.rowsToGenerate();

      // Functions:
      var changeValue = function (value) {
        return function () {
          game.rowsToGenerate(value);

          game.save();
        };
      };

      var scrollSlide = function (direction) {
        return function () {
          var scrollDistance = $innerWrapper.clientWidth / 4;
          var scrollPosition = $innerWrapper.scrollLeft;
          var directionSymbol = direction === 'right' ? 1 : -1;

          $DS($innerWrapper).animate({ scrollLeft: scrollPosition + (scrollDistance * directionSymbol) }, 500, function () {
            m.redraw();
          });
        };
      };

      var rowsClass = function () {
        var className = 'eurojackpot-system-game-header';
        var containerWidth = $container.offsetWidth;
        var optionsContainerWidth = $optionsContainer.offsetWidth;

        if (containerWidth < optionsContainerWidth) {
          var direction = function () {
            if ($innerWrapper.scrollLeft > 30) {
              className += ' overflown-left';
            }
            if ($innerWrapper.scrollLeft < optionsContainerWidth - $innerWrapper.offsetWidth) {
              className += ' overflown-right';
            }
          };
          direction();
          $DS($innerWrapper).scroll(function () {
            clearTimeout($DS.data(this, 'scrollCheck'));
            $DS.data(this, 'scrollCheck', setTimeout(function () {
              direction();
              m.redraw();
            }, 250));
          });
        }

        $container.className = className;
      };

      // Render:
      render.ready.then(function (nodes) {
        $container = nodes['container'].$element;
        $innerWrapper = nodes['innerWrapper'].$element;
        $optionsContainer = nodes['optionsContainer'].$element;
        $elements.push(nodes['title'].$element); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector

        for (var i = 0; i < nodes['lucky'].length; i++) {
          $elements.push(nodes['lucky'][i].$element); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        }

        rowsClass();

        if (!nodes['container'].rendered) {
          Resize(function () {
            rowsClass();
          });
        }
      });

      // View:
      return m('div', { config: render.depend('container') }, [
        m('div', { class: 'game-switch-wrapper' }, [
          m('div', { class: 'switch-button next', onclick: scrollSlide('right') }, [
            m('svg', { class: 'icon' }, [
              m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-right-arrow' })
            ])
          ]),
          m('div', { class: 'switch-inner-wrapper', config: render.depend('innerWrapper') }, [
            m('span', { class: 'switch-items-wrapper', config: render.depend('optionsContainer') }, [
              m('span', { class: 'switch-title', config: render.depend('title') }, d('EurojackpotLucky/OptionSelectorTitle')),
              rows.map(function (value) {
                return m('span', { class: 'switch-item' + (rowsToGenerate === value ? ' active' : ''), config: render.depend('lucky'), onclick: changeValue(value) }, value);
              })
            ])
          ]),
          m('div', { class: 'switch-button prev', onclick: scrollSlide('left') }, [
            m('svg', { class: 'icon' }, [
              m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-left-arrow' })
            ])
          ])
        ])
      ]);

    };

    // Public functions:
    return OptionSelector;

  });
