defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Templates/EurojackpotClassic/RowsList',
  [
    'Common/Framework/EventHandling/Scripts/OutsideClick',
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotUtils'
  ],
  function (OutsideClick, m, Render, Utils) {

    // Template:
    var RowsList = function (controller) {

      // Variables:
      var currentRow = controller.property('currentRow');
      var d = controller.d;
      var game = controller.game();
      var rows = game.getRows();
      var numbersLeftPerRow = game.numbersPerRow() - game.getRow(currentRow).numbers.length;
      var starNumbersLeftPerRow = game.starNumbersPerRowMin() - game.getRow(currentRow).starNumbers.length;
      var maxRows = game.classicMaxRows();
      var validRows = game.getRows(true).length;
      var rowsLength = rows.length;
      var nextRow = rowsLength > currentRow ? game.getRow(currentRow + 1).numbers.length : 0;
      var hasMoreRows = currentRow < rowsLength;
      var showCreate = game.isRowValid(currentRow) && hasMoreRows && nextRow === 0 && currentRow < maxRows && currentRow + 1 > rowsLength - 2;
      var render = Render();

      // Functions:
      var rowsClass = function () {
        var className = 'rows-list-box';
        if (controller.property('toggledRowsList')) {
          className += ' active';
        }
        return className;
      };

      var rowsListToggle = function () {
        controller.property('toggledRowsList', !controller.property('toggledRowsList'));
      };

      var changeRow = function (rowNumber) {
        return function () {
          if (rowNumber !== currentRow) {
            controller.property('animate', rowNumber < currentRow ? 'left' : 'right');
            controller.property('currentRow', rowNumber);
            if (Utils.isMobile()) {
              controller.property('toggledRowsList', false);
            }
          }
        };
      };

      var autogenerateNumbers = function () {
        controller.property('callToAutogenerateNumbers', true);

        m.redraw();
      };

      var createNewRow = function () {
        controller.property('callToCreateNewRow', true);

        m.redraw();
      };

      var deleteRow = function (rowNumber) {
        return function (event) {
          event.stopPropagation();

          controller.property('animate', 'remove');

          if (typeof controller.deleteOverlay !== 'undefined') {
            controller.property('deleteRow', rowNumber);

            controller.deleteOverlay().show(function () {
              var deleteRow = controller.property('deleteRow');

              if (deleteRow && currentRow !== deleteRow) {
                game.removeRow(deleteRow);

                if (currentRow > deleteRow) {
                  controller.property('currentRow', currentRow - 1);
                }

                if (!game.isGameValid() && controller.back) {
                  controller.back();
                }
              } else {
                if (currentRow === game.rows().length - 1) {
                  game.addRow();
                }

                controller.property('animate', 'remove');

                game.getRow(currentRow).state = 'remove';
              }
            });
          }
        };
      };
      // Renders:
      render.ready.then(function (nodes) {
        var rowsList = nodes['rows-list'];

        var $rowsList = rowsList.$element;

        var windowWidth = window.innerWidth;
        var rowsListWidth = $rowsList.clientWidth;

        if (!Utils.isMobile()) {
          if (controller.property('toggledRowsList')) {
            document.getElementsByClassName('rows-container')[0].style.transform = 'scale(' + (1 - ((rowsListWidth / (windowWidth / 100)) / 99.1)) + ')';
          } else {
            document.getElementsByClassName('rows-container')[0].style.transform = 'scale(1)';
          }
        }

        // Outside click:
        if (!rowsList.rendered) {
          OutsideClick(rowsList.$element, rowsList.context, function () {
            if (Utils.isMobile()) {
              controller.property('toggledRowsList', false);
            }
          });
        }
      });

      // View:
      return m('div', { class: rowsClass(), config: render.depend('rows-list') }, [
        m('div', { class: 'toggle-button', onclick: rowsListToggle }, [
          m('svg', { class: 'icon-list' }, [
            m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-rows-list' })
          ]),
          m('svg', { class: 'icon-arrow' }, [
            m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-left-arrow' })
          ]),
          function () {
            if (validRows > 0) {
              return [m('span', { class: 'button-count' }, validRows)];
            }
          }()
        ]),
        m('div', { class: 'container' }, [
          m('div', { class: 'items-box' }, [
            m('div', { class: 'rows-list-header' }, validRows != 1 ? d('EurojackpotClassic/RowsListHeaderAmountPluralis', { rows: validRows }) : d('EurojackpotClassic/RowsListHeaderAmountSingular')),
            m('span', { class: 'error-message-box' }, [
              m('span', { class: 'error-headline' }, d('EurojackpotClassic/RowsListErrorTitle')),
              m('span', { class: 'error-subheadline' }, d('EurojackpotClassic/RowsListErrorText'))
            ]),
            m('div', { class: 'information-text-wrapper' + (validRows === 0 ? '' : ' remove') }, [
              m('div', { class: 'text-row' }, d('RowHeader/Description', { gameNumbersPerRow: numbersLeftPerRow, gameStarNumbersPerRow: starNumbersLeftPerRow })),
              m('div', { class: 'text-row' }, d('EurojackpotClassic/NavigationCreateNewRow'))
            ]),
            m('div', { class: 'items-inner-box' }, [
              rows.map(function (row, index) {
                if (row.numbers.length > 0) {
                  return m('div', { class: 'item' + (game.isRowValid(index + 1) ? '' : ' invalid-row') + (index + 1 == currentRow ? ' active-row' : '') }, [
                    m('div', { class: 'item-inner', onclick: changeRow(index + 1) }, [
                      m('div', { class: 'headline' }, d('EurojackpotClassic/RowsListRow') + ' ' + (index + 1)),
                      m('div', { class: 'numbers-container clearfix' }, [
                        m('span', row.numbers.map(function (single) {
                          return single.number;
                        }).join(', ')),

                        m('svg', { class: 'plus-separator icon-plus icon' }, [
                          m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-plus' })
                        ]),

                        m('span', { class: 'star-numbers' }, row.starNumbers.map(function (single) {
                          return single.number;
                        }).join(', '))
                      ]),
                      m('div', { class: 'delete-button', onclick: deleteRow(index + 1) }, [
                        m('svg', { class: 'icon' }, [
                          m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-x' })
                        ])
                      ])
                    ])
                  ]);
                }
              })
            ]),
            function () {
              if (!game.isRowValid(currentRow) || showCreate) {
                return [m('div', { class: 'quick-button-wrapper' }, [
                  m('div', { class: 'quick-button' + (!game.isRowValid(currentRow) ? '' : ' inactive'), onclick: autogenerateNumbers }, d('NumbersPickerButtons/NumbersFooterAuto')),
                  m('div', { class: 'quick-button' + (showCreate ? '' : ' inactive'), onclick: createNewRow }, d('EurojackpotClassic/RowsListCreateNewRowButton'))
                ])];
              }
            }()
          ])
        ])
      ]);
    };

    // Public functions:
    return RowsList;

  });
