defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Templates/RowNumbers',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Templates/RowNumber'
  ],
  function (m, RowNumber) {

    // Context:
    var RowNumbers = function (controller, rowNumber) {

      // Variables:
      var currentRow = controller.property('currentRow');
      var dom = [];
      var game = controller.game();
      var number = 0;
      var totalNumbers = game.numbersIntervalMax();

      // Functions:
      var numberConfig = function (number) {
        if (rowNumber + 1 === currentRow) {
          return function ($element, rendered, context) {
            if (rendered && context.rowNumber === rowNumber) {
              return false;
            }

            context.rowNumber = rowNumber;

            $element = $DS($element);

            $element.unbind('click touchend touchmove touchstart');

            var touchClick = false;
            var touchEnd = false;
            var touchMove = false;
            var touchTimer = null;

            $element.on('click', function () {
              if (!touchClick) {
                numberToggle(number);
              }
            });

            $element.on('touchstart', function () {
              clearTimeout(touchTimer);

              touchClick = false;
              touchEnd = false;
              touchMove = false;

              touchTimer = setTimeout(function () {
                if (!touchEnd && !touchMove) {
                  touchClick = true;

                  $element.addClass('active');
                }
              }, 250);
            });

            $element.on('touchend', function () {
              touchEnd = true;

              if (touchClick) {
                $element.removeClass('active');

                numberToggle(number);

                m.redraw();
              }
            });

            $element.on('touchmove', function () {
              touchMove = true;
            });

            $element.on('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function () {
              controller.property('numberSelected' + number, false);
            });
          };
        }
      };

      var numberToggle = function (number) {
        if (!controller.property('autogeneratingNumbers')) {
          var hasNumber = game.hasNumber(currentRow, number);
          var nextRow = game.getRow(currentRow + 1);
          var numbers = game.getRow(currentRow).numbers.length;
          var totalNumbers = numbers + game.getRow(currentRow).starNumbers.length;

          if (numbers < game.numbersPerRowMax() && totalNumbers < game.totalNumbersPerRowMax() && !hasNumber) {
            game.addNumber(currentRow, number);

            if (game.isRowValid(currentRow)) {
              controller.property('animate', 'row-completed');

              if (!nextRow) {
                game.addRow();
              }
            }
          } else if (hasNumber) {
            game.removeNumber(currentRow, number);

            var lastRow = game.getRow(game.rows().length);

            if (nextRow.count === lastRow.count) {
              lastRow = null;
            }

            if (!game.isRowValid(currentRow) && nextRow && nextRow.numbers.length === 0 && nextRow.starNumbers.length === 0 && lastRow && lastRow.numbers.length === 0 && lastRow.starNumbers.length === 0) {
              game.removeRow(currentRow + 2);
            }

            if (controller.property('animate') === 'row-completed') {
              controller.property('animate', '');
            }
          } else {
            controller.property('animate', 'shake');
          }

          if (controller.property('animate') !== 'shake') {
            controller.property('numberSelected' + number, true);
          }

          m.redraw();
        }
      };

      // View:
      while (number < totalNumbers) {
        number++;

        var className = '';
        var hasNumber = game.hasNumber(rowNumber + 1, number);

        if (hasNumber) {
          className = className + ' selected';
        }

        if (controller.property('numberSelected' + number) && rowNumber + 1 === currentRow) {
          className = className + ' flip-animation-in-progress ' + (hasNumber ? '' : 'de') + 'select-number-animation';
        }

        dom.push(RowNumber(controller, number, className, numberConfig(number), rowNumber));
      }

      return m('div', { class: 'cells-container' }, dom);
    };

    // Public functions:
    return RowNumbers;

  });
