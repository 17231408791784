defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Templates/RowNumbersFooter',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Shake'
  ],
  function (m, Shake) {

    // Template:
    var RowNumbersFooter = function (controller, rowNumber) {

      // Variables:
      var currentRow = rowNumber + 1;
      var d = controller.d;
      var game = controller.game();
      var gameNumbersPerRowMax = game.numbersPerRowMax();
      var gameStarNumbersPerRowMax = game.starNumbersPerRowMax();
      var nextRow = game.getRow(rowNumber + 2);
      var numbers = game.getRow(rowNumber + 1).numbers;
      var numbersAmount = numbers.length;
      var starNumbers = game.getRow(rowNumber + 1).starNumbers;
      var starNumbersAmount = starNumbers.length;
      var playType = game.playType();
      var allNumbersSelected = numbersAmount === gameNumbersPerRowMax && starNumbersAmount === gameStarNumbersPerRowMax;

      // Functions:
      var autogenerateNumbersAction = function () {
        currentRow = controller.property('currentRow');
        numbers = game.getRow(currentRow).numbers;
        numbersAmount = numbers.length;
        starNumbers = game.getRow(rowNumber + 1).starNumbers;
        starNumbersAmount = starNumbers.length;

        if ((numbersAmount === game.numbersPerRowMax() && starNumbersAmount === game.starNumbersPerRowMax()) || controller.property('autogeneratingNumbers')) {
          return false;
        }

        controller.property('autogeneratingNumbers', true);

        var chosen = [];
        var chosenStars = [];
        var row = game.getRow(currentRow);
        var currentRowNumbers = row.numbers;
        var currentRowStarNumbers = row.starNumbers;
        var single = null;

        for (var i = 0; i < currentRowNumbers.length; i++) {
          single = currentRowNumbers[i];

          if (!single.autogenerated) {
            chosen.push(single);
          }
        }

        for (var j = 0; j < currentRowStarNumbers.length; j++) {
          single = currentRowStarNumbers[j];

          if (!single.autogenerated) {
            chosenStars.push(single);
          }
        }

        row.numbers = chosen;
        row.starNumbers = chosenStars;

        game.setRow(currentRow, row);

        m.startComputation();

        game.autogenerateNumbers(currentRow).then(function () {
          var numbers = game.getRow(currentRow).numbers;
          var starNumbers = game.getRow(currentRow).starNumbers;

          for (var i = 0; i < numbers.length; i++) {
            var number = numbers[i];

            if (number.autogenerated) {
              controller.property('numberSelected' + number.number, 'autogenerated');
            }
          }

          for (var j = 0; j < starNumbers.length; j++) {
            var starNumber = starNumbers[j];

            if (starNumber.autogenerated) {
              controller.property('starNumberSelected' + starNumber.number, 'autogenerated');
            }
          }

          controller.property('autogeneratingNumbers', false);

          m.endComputation();

          if (game.isRowValid(currentRow)) {
            controller.property('animate', 'row-completed');
          }

          m.redraw();
        }, function () {
          controller.property('autogeneratingNumbers', false);

          m.endComputation();

          m.redraw();
        });
      };

      if (controller.property('callToAutogenerateNumbers')) {
        autogenerateNumbersAction();

        controller.property('callToAutogenerateNumbers', false);
      }

      var autogenerate = function ($element, isRendered) {
        if (!isRendered) {
          $element.addEventListener('click', function () { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            autogenerateNumbersAction();
          });
        }

        // Shake:
        Shake(function () {
          if (typeof window.DeviceMotionEvent !== 'undefined' && controller.property('currentRow') === rowNumber + 1) {
            $DS($element).click();
          }
        });

      };

      var resetClass = function () {
        return numbersAmount + starNumbersAmount === 0 ? ' inactive' : '';
      };

      var resetRow = function () {
        for (var i = 0; i < numbersAmount; i++) {
          controller.property('numberSelected' + numbers[i].number, 'reset');
        }

        game.resetRow(rowNumber + 1);

        var lastRow = game.getRow(game.rows().length);

        if (nextRow.count === lastRow.count) {
          lastRow = null;
        }

        if (nextRow && nextRow.numbers.length === 0 && lastRow && lastRow.numbers.length === 0) {
          game.removeRow(currentRow + 2);
        }
      };

      // View:
      return m('div', { class: 'number-picker-footer clearfix' }, [
        m('div', { class: 'buttons-container', key: 'row-numbers-header-' + rowNumber }, [
          function () {
            if (playType !== 'Lucky') {
              return m('div', { class: 'autogenerate-box' + (controller.property('currentRow') === rowNumber + 1 ? ' active' : '') + (allNumbersSelected ? ' inactive' : ''), config: autogenerate }, [
                m('span', { class: 'autogenerate-button button' + (allNumbersSelected ? ' inactive' : '') + (controller.property('autogeneratingNumbers') && controller.property('currentRow') === rowNumber + 1 ? ' autogenerating-numbers' : '') }, [
                  d('NumbersPickerButtons/NumbersFooterAuto')
                ])
              ]);
            }
          }(),
          m('span', { class: 'reset-row-button button' + resetClass(), onclick: resetRow }, [
            d('NumbersPickerButtons/NumbersFooterClear')
          ])
        ])
      ]);

    };

    // Public functions:
    return RowNumbersFooter;

  });
