defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Components/EurojackpotLightning',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/ErrorOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/JokerOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/SubscriptionValidationOverlay',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotDictionary',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotInfo',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Models/EurojackpotGame',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Templates/PurchaseBar',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Templates/EurojackpotLightning/RotarySelector',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/NotificationBox'
  ],
  function (Component, Params, Overlay, ErrorOverlay, JokerOverlay, SubscriptionValidationOverlay, EurojackpotDictionary, EurojackpotInfo, EurojackpotGame, PurchaseBar, RotarySelector, NotificationBox) {

    // Component:
    Component('eurojackpot-lightning', [EurojackpotInfo, EurojackpotDictionary], function (m, route, settings, property) {

      // Components:
      var root = {
        controller: function () {

          // Variables:
          this.d = EurojackpotDictionary.get.bind();
          this.isFeedDown = m.prop(EurojackpotInfo.isFeedDown());
          this.property = property;

          if (!this.isFeedDown()) {
            this.gameInstanceId = m.prop(Params.extract('gameInstanceId'));
            this.game = m.prop(EurojackpotGame.setupGame({ gameInstanceId: this.gameInstanceId(), playType: 'Lightning', rowsToGenerate: EurojackpotInfo.getLightningDefaultRowCount() }));
            this.errorOverlay = m.prop(new Overlay(ErrorOverlay('eurojackpot-lightning-game-overlay', { header: this.d('GenerateRowsErrorOverlay/Header'), body: this.d('GenerateRowsErrorOverlay/Body'), dismiss: this.d('GenerateRowsErrorOverlay/Dismiss') })));
            this.jokerEnabled = m.prop(settings.jokerEnabled);
            this.jokerOverlay = m.prop(new Overlay(JokerOverlay(this.game(), 'eurojackpot-lightning-game-overlay', this.d, EurojackpotInfo.jokerData())));
            this.subscriptionValidationOverlay = m.prop(new Overlay(SubscriptionValidationOverlay('eurojackpot-lightning-game-overlay subscription-validation-overlay', { header: this.d('SubscriptionValidationOverlay/Header'), body: this.d('SubscriptionValidationOverlay/Body'), dismiss: this.d('SubscriptionValidationOverlay/Dismiss') })));
            this.rows = this.game().rowsToGenerate;
            this.rowsOptions = m.prop(EurojackpotInfo.getLightningOptions());

            // Functions:
            this.purchase = function () {
              var game = this.game();

              // Abort purchase and show subscription validation overlay, if subscription is chosen but not valid
              if (game.numberOfDraws() === 0 && !game.isSubscriptionValid()) {
                this.subscriptionValidationOverlay().show();

                return;
              }

              var deferred = m.deferred();
              var self = this;

              if (this.jokerEnabled()) {
                this.jokerOverlay().show(deferred.resolve);
              } else {
                deferred.resolve();
              }

              deferred.promise.then(function () {
                game.prepareConfirm().then(function (confirmUrl) {
                  if (game.numberOfDraws() === 0) {
                    game.sendToSubscription();
                  } else {
                    location.href = confirmUrl;
                  }
                }, function () {
                  self.errorOverlay().show();
                });
              });
            }.bind(this);
          }
        },

        view: function (controller) {
          return m('div', { class: 'eurojackpot-section eurojackpot-game-section purchase-bar-container' }, [
            m('div', { class: 'eurojackpot-content-wrapper' }, [
              !EurojackpotInfo.noOpenDraw() ? [
                m('h2', { class: 'headline' }, controller.d('EurojackpotLightning/Headline')),
                m('h3', { class: 'subheadline' }, controller.d('EurojackpotLightning/ChooseRows')),
                RotarySelector(controller.rowsOptions, controller.rows, controller.d('EurojackpotLightning/Row' + (controller.rows() !== 1 ? 's' : ''))),
                NotificationBox('ejp_lyn', settings.notifications),
                PurchaseBar(controller)
              ] : [
                m('div', { class: 'game-not-available-wrapper' }, [
                  settings.iconUrl.length > 0 ? m('img', { class: 'not-available-icon', src: settings.iconUrl }) : '',
                  !controller.isFeedDown() ? [
                    m('h2', { class: 'headline' }, controller.d('EurojackpotClosed/FrontpageLightning/NoOpenDrawHeader')),
                    m('h3', { class: 'subheadline' }, controller.d('EurojackpotClosed/FrontpageLightning/NoOpenDrawText_1')),
                    m('h3', { class: 'subheadline' }, controller.d('EurojackpotClosed/FrontpageLightning/NoOpenDrawText_2'))
                  ] : [
                    m('h2', { class: 'headline' }, controller.d('EurojackpotClosed/FrontpageLightning/GameNotAvailableHeader')),
                    m('h3', { class: 'subheadline' }, controller.d('EurojackpotClosed/FrontpageLightning/GameNotAvailableText_1')),
                    m('h3', { class: 'subheadline' }, controller.d('EurojackpotClosed/FrontpageLightning/GameNotAvailableText_2'))
                  ]
                ])
              ]
            ])
          ]);
        }
      };

      // Routes:
      route('/', root);

    });

  });

