defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Templates/EurojackpotWinningNumbers/Numbers',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils'
  ],
  function (m, Utils) {

    // Template:
    var Numbers = function (controller, numbers, type) {

      // Variables:
      var d = controller.d;
      var weekNumber;
      var headLine;

      if (controller.gameDate && controller.gameDate()) {
        weekNumber = Utils.getWeekNumber(controller.gameDate());
      }

      if (weekNumber) {
        if (type === 'bonus') {
          headLine = d('WinningNumbers/BonusNumbersWithWeek', { week: weekNumber });
        } else if (type === 'joker') {
          headLine = d('WinningNumbers/JokerNumbersWithWeek', { week: weekNumber });
        } else {
          headLine = d('WinningNumbers/WinningNumbersWithWeek', { week: weekNumber });
        }
      } else {
        if (type === 'bonus') {
          headLine = d('WinningNumbers/BonusNumbers');
        } else if (type === 'joker') {
          headLine = d('WinningNumbers/JokerNumbers');
        } else {
          headLine = d('WinningNumbers/WinningNumbers');
        }
      }

      // Return:
      return [
        m('div', { class: 'number-container' }, [
          m('h2', { class: 'numbers-headline' }, headLine),
          m('ul', { class: type === 'bonus' ? 'bonus' : '' }, numbers ? numbers.map(function (number) {
            var checkedNumber = number || '-';
            if (type === 'bonus') {
              return m('li', [
                m('svg', { class: 'number-icon star' }, [
                  m('use', { href: '/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons.svg#icon-star-gold' })
                ]),
                m('span', checkedNumber)
              ]);
            } else if (type === 'joker') {
              return m('li', [
                m('svg', { class: 'number-icon joker' }, [
                  m('use', { href: '/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons.svg#icon-joker-numbershape' })
                ]),
                m('span', number)
              ]);
            } else {
              return m('li', [
                m('svg', { class: 'number-icon' }, [
                  m('use', { href: '/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons.svg#square-shape-gold_with-gradient' })
                ]),
                m('span', checkedNumber)
              ]);
            }
          }) : null)
        ])
      ];

    };

    // Public functions:
    return Numbers;

  });
