defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Templates/EurojackpotClassic/Navigation', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Common/Framework/EventHandling/Scripts/Event',
], function (m, Event) {
  let prevShowCreate = false;

  // Template:
  var Navigation = function (controller) {

    // Variables:
    var currentRow = controller.property('currentRow');
    var game = controller.game();
    var rows = game.rows();
    var rowsLength = rows.length;
    var maxRows = game.classicMaxRows();
    var hasLastRow = currentRow + 2 <= rows.length && currentRow <= maxRows - 1;
    var minimum = game.numbersPerRowMin();
    var minimumStars = game.starNumbersPerRowMin();
    var nextRow = rowsLength > currentRow ? game.getRow(currentRow + 1).numbers.length : 0;
    var numbers = game.getRow(currentRow).numbers.length;
    var starNumbers = game.getRow(currentRow).starNumbers.length;
    var hasMoreRows = currentRow < rowsLength;
    var showCreate = numbers >= minimum && starNumbers >= minimumStars && hasMoreRows && nextRow === 0 && rowsLength < maxRows + 1;
    var showNext = (numbers >= minimum && hasMoreRows && nextRow > 0) || (hasLastRow);
    var showRightGradient = maxRows > currentRow;

    // Functions:
    var slideAction = function (direction) {
      if (direction === 'right') {
        var lastRowEmpty = game.getRow(rowsLength).numbers.length === 0;
        var beforeLastRow = currentRow + 1 === rowsLength;
        var rowValid = game.isRowValid(currentRow);

        if (lastRowEmpty && beforeLastRow && !rowValid) {
          controller.property('animate', 'row-not-completed');
        } else if (hasMoreRows) {
          if (currentRow + 1 === rowsLength) {
            game.addRow();
          }

          controller.property('currentRow', currentRow + 1);
          controller.property('animate', direction);
        }
      } else if (direction === 'left' && currentRow > 1) {
        controller.property('currentRow', currentRow - 1);
        controller.property('animate', direction);
      }
    };
    var slide = function (direction) {
      return function () {
        slideAction(direction);
      };
    };

    if (controller.property('callToCreateNewRow')) {
      slideAction('right');

      controller.property('callToCreateNewRow', false);
    }

    // If showCreate changes, send an event to the PlusWizard to update the ProceedBar.
    if ((prevShowCreate && !showCreate) || (!prevShowCreate && showCreate)) {
      prevShowCreate = showCreate;
      Event.fire('eurojackpot:classic:canCreateNewRow', showCreate);
    }

    // View:
    return [
      m('div', { class: 'left-gradient', onclick: slide('left') }),
      m('div', { class: 'prev-row-button' + (currentRow > 1 ? ' active' : ''), onclick: slide('left') }, [
        m('svg', { class: 'icon icon-left-arrow' }, [
          m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-left-arrow' })
        ])
      ]),
      m('div', { class: 'create-new-row-button' + (showCreate ? ' active' : ''), onclick: slide('right') }, [
        m('svg', { class: 'icon icon-plus' }, [
          m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-plus' })
        ])
      ]),
      m('div', { class: 'right-gradient' + (showRightGradient ? ' active' : ''), onclick: slide('right') }, [
        !controller.hasDisplayedCreateNewRowHelp() ? m('div', { class: 'create-new-row-text' }, [
          m('div', { class: 'create-new-row-text-box' }, controller.d('EurojackpotClassic/NavigationCreateNewRow')),
          m('div', { class: 'create-new-row-text-arrow' })
        ]) : null
      ]),
      m('div', { class: 'next-row-button' + (showNext ? ' active' : ''), onclick: slide('right') }, [
        m('svg', { class: 'icon icon-right-arrow' }, [
          m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-right-arrow' })
        ])
      ])
    ];
  };

  // Public functions:
  return Navigation;

});
