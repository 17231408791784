defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Templates/RowHeader',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril'
  ],
  function (m) {

    // Template:
    var RowHeader = function (controller, rowNumber) {

      // Variables:
      var currentRow = controller.property('currentRow');
      var d = controller.d;
      var game = controller.game();
      var row = game.getRow(currentRow);
      var numbersPerRow = game.numbersPerRow();
      var numbers = row.numbers.length;
      var numbersLeftPerRow = numbersPerRow - game.getRow(rowNumber + 1).numbers.length;
      var starNumbersLeftPerRow = game.starNumbersPerRowMin() - game.getRow(rowNumber + 1).starNumbers.length;
      var rows = game.rows();
      var rowsCount = rows.length;
      var notDeletable = rowsCount === 1 || (rowsCount === currentRow && numbers === 0) || (numbers === 0 && row.starNumbers.length === 0);

      // Functions:
      var remove = function () {
        if (typeof controller.deleteOverlay !== 'undefined') {
          controller.deleteOverlay().show(function () {
            var deleteRow = controller.property('deleteRow');

            if (deleteRow && currentRow !== deleteRow) {
              game.removeRow(deleteRow);

              if (currentRow > deleteRow) {
                controller.property('currentRow', currentRow - 1);
              }

              if (!game.isGameValid() && controller.back) {
                controller.back();
              }
            } else {
              if (currentRow === game.rows().length - 1) {
                game.addRow();
              }

              controller.property('animate', 'remove');

              game.getRow(currentRow).state = 'remove';
            }
          });
        }
      };

      var thisRow = function () {
        return rowNumber === rowsCount ? rowNumber + 2 : rowNumber + 1;
      };

      var totalRows = function () {
        var count = rowNumber + 1 === rowsCount ? rowsCount : rowsCount - 1;

        if (!game.isRowValid(rowsCount) && !game.isRowValid(rowsCount - 1) && rowNumber + 1 < rowsCount - 1) {
          count--;
        }

        return count;
      };

      // View:
      return m('div', { class: 'row-header' }, [
        m('div', { class: 'right-side' }, [
          m('span', { class: 'header-rules-text' }, ((numbersLeftPerRow === 0 && starNumbersLeftPerRow === 0) ? d('RowHeader/DescriptionAllNumbersSelected') : d('RowHeader/Description', { gameNumbersPerRow: numbersLeftPerRow, gameStarNumbersPerRow: starNumbersLeftPerRow }))),
          m('span', { class: 'row-delete-button' + (notDeletable ? ' inactive' : ''), onclick: (notDeletable ? null : remove) }, [
            m('svg', { class: 'icon' }, [
              m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-x' })
            ])
          ])
        ]),
        m('span', { class: 'counter' }, [
          d('RowHeader/Row') + ' ',
          m('span', { class: 'counter-current' }, thisRow()),
          ' ' + d('RowHeader/Of') + ' ',
          m('span', { class: 'counter-total' }, totalRows())
        ])
      ]);
    };

    // Public functions:
    return RowHeader;

  });
