defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Templates/EurojackpotSystem/Accordion',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render'
  ],
  function (m, Render) {

    // View:
    var Accordion = function (controller, type, data) {

      // Variables:
      var breakAt = 50;
      var d = controller.d;
      var game = controller.game();
      var isGameValid = game.isGameValid();
      var numbers = data.name ? data.name.split('+') : [];
      var render = Render();
      var rowSpan = (data.index * breakAt) + breakAt;
      var system = game.systemName();
      var maxRows = parseInt(system.split('-')[1], 10);

      if (type === 'winnings') {
        var winningsRows = {};
        var winningsRowsUpper = ['5 + 2', '5 + 1', '5 + 0', '4 + 2', '4 + 1', '4 + 0'];
        var winningsRowsLower = ['3 + 2', '3 + 1', '2 + 2', '3 + 0', '1 + 2', '2 + 1'];

        for (var i = 0; i < data.rows.length; i++) {
          var row = data.rows[i];

          winningsRows[row.mainNumbers + ' + ' + row.starNumbers] = row.value;
        }
      }

      // Render:
      render.ready.then(function (nodes) {
        var body = nodes['body'];
        var buttonLeft = nodes['button-left'];
        var buttonRight = nodes['button-right'];
        var header = nodes['header'];
        var rows = nodes['row'];

        if (!body.rendered) {
          if (typeof controller.property('AccordionToggled' + data.index) === 'undefined') {
            controller.property('AccordionToggled' + data.index, false);
          }

          if (typeof controller.property('AccordionButtonToggled' + data.index) === 'undefined') {
            controller.property('AccordionButtonToggled' + data.index, 'left');
          }

          header.$element.addEventListener('click', function () { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            controller.property('AccordionToggled' + data.index, !controller.property('AccordionToggled' + data.index));

            m.redraw();
          });

          if (type === 'winnings') {
            buttonLeft.$element.addEventListener('click', function () { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
              controller.property('AccordionButtonToggled' + data.index, 'left');

              m.redraw();
            });

            buttonRight.$element.addEventListener('click', function () { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
              controller.property('AccordionButtonToggled' + data.index, 'right');

              m.redraw();
            });
          }

          setTimeout(function () {
            m.redraw();
          }, 250);
        }

        var height = 0;

        if (type === 'key-tab') {
          for (var i = 0; i < rows.length; i++) {
            height += $DS(rows[i].$element).outerHeight();
          }
        } else {
          height = $DS(nodes['inner-body'].$element).outerHeight();
        }

        controller.property('AccordionHeight' + data.index, Math.ceil(height * 1.2));
      });

      // View:
      return m('div', { class: 'accordion-wrapper' + (controller.property('AccordionToggled' + data.index) ? ' active' : ''), config: render.depend() }, [
        m('div', { class: 'accordion-header', config: render.depend('header') }, [
          m('span', { class: 'title' }, type === 'key-tab' ? d('EurojackpotSystem/KeyTab/Row', { number: ((data.index * breakAt) + 1) + '-' + (rowSpan > maxRows ? maxRows : ((data.index * breakAt) + breakAt)) }) : d('EurojackpotSystem/ProfitGuaranteeTab/RowHeader', { numbers: numbers[0], starNumbers: numbers[1] })),
          m('svg', { class: 'icon ' }, [
            m('use', { class: 'arrow-down', href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-down-arrow' }),
            m('use', { class: 'close', href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-up-arrow' })
          ])
        ]),

        // Key tab:
        type === 'key-tab' ? [
          m('div', { class: 'accordion-body', config: render.depend('body'), style: controller.property('AccordionToggled' + data.index) ? 'max-height: ' + controller.property('AccordionHeight' + data.index) + 'px;' : null }, [
            m('table', { class: 'simple-custom-table', config: render.depend('table') }, [
              m('tbody', function () {
                return data.rows.map(function (row, index) {
                  return m('tr', [
                    m('td', d('EurojackpotSystem/KeyTab/Row', { number: index + ((data.index * breakAt) + 1) })),
                    m('td', { config: render.depend('row') }, isGameValid && row.numbers ? row.numbers.map(function (number) {
                      return number.number;
                    }).join(', ') : row.join(', '))
                  ]);
                });
              }())
            ])
          ])

          // Winnings:
        ] : [
          m('div', { class: 'accordion-body', config: render.depend('body'), style: controller.property('AccordionToggled' + data.index) ? 'max-height: ' + controller.property('AccordionHeight' + data.index) + 'px;' : null }, [
            m('div', { class: 'accordion-inner-body', config: render.depend('inner-body') }, [
              m('div', { class: 'accordion-headline' }, d('EurojackpotSystem/ProfitGuaranteeTab/RowDescription', { numbers: numbers[0], starNumbers: numbers[1], system: system })),
              m('div', { class: 'accordion-tabs-wrapper' }, [
                m('table', { class: 'accordion-tabs-navigation' }, [
                  m('tr', { config: render.depend('row') }, [
                    m('td', { class: 'accordion-tab-button' + (controller.property('AccordionButtonToggled' + data.index) === 'left' ? ' active' : ''), config: render.depend('button-left') }, '5 + 2 til 4 + 0'),
                    m('td', { class: 'accordion-tab-button' + (controller.property('AccordionButtonToggled' + data.index) === 'right' ? ' active' : ''), config: render.depend('button-right') }, '3 + 2 til 2 + 1')
                  ])
                ]),
                m('div', { class: 'accordion-tabs-body' }, [
                  m('table', { class: 'accordion-tab-table' + (controller.property('AccordionButtonToggled' + data.index) === 'left' ? ' active' : '') }, [
                    m('tr', [
                      winningsRowsUpper.map(function (row) {
                        return m('td', row);
                      })
                    ]),
                    m('tr', [
                      winningsRowsUpper.map(function (row) {
                        return m('td', winningsRows[row] ? winningsRows[row] : '-');
                      })
                    ])
                  ]),
                  m('table', { class: 'accordion-tab-table' + (controller.property('AccordionButtonToggled' + data.index) === 'right' ? ' active' : '') }, [
                    m('tr', [
                      winningsRowsLower.map(function (row) {
                        return m('td', row);
                      })
                    ]),
                    m('tr', [
                      winningsRowsLower.map(function (row) {
                        return m('td', winningsRows[row] ? winningsRows[row] : '-');
                      })
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]
      ]);
    };

    // Public functions:
    return Accordion;

  });
