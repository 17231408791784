defineDs('DanskeSpil/Domain/Eurojackpot/Scripts/Templates/EurojackpotWinningNumbers/Drawing',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Templates/EurojackpotWinningNumbers/Numbers',
    'DanskeSpil/Domain/Eurojackpot/Scripts/Templates/EurojackpotWinningNumbers/Results'
  ],
  function (m, Render, Numbers, Results) {

    // Template:
    var Drawing = function (controller, data, type) {
      var render = Render();

      render.ready.then(function (nodes) {
        var featuredWinners = nodes['featured-winners'];

        if (!featuredWinners.rendered) {
          var $element = featuredWinners.$element;

          $element.addEventListener('click', function () { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            $element.classList.toggle('hide');
          });
        }
      });

      var featuredWinners = function (winners, game) {
        if (!winners) return null;

        return m('div', { class: 'featured-winners hide', config: render.depend('featured-winners') }, [
          m('div', { class: 'header' }, [
            controller.d('WinningNumbers/FeaturedWinners/' + game),
            m('svg', { class: 'icon-arrow' }, [
              m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-up-arrow' })
            ])
          ]),
          m('div', { class: 'text' }, [
            // Trust the content, as it contains the raw value from the api, and it contains HTML markup
            m.trust(winners)
          ])
        ]);
      };

      // Return:
      return type === 'eurojackpot' ? // Eurojackpot
        m('div', { class: 'winning-numbers-element' }, [
          m('div', { class: 'numbers' }, [
            Numbers(controller, data.winningNumbers),
            Numbers(controller, data.bonusNumbers, 'bonus')
          ]),
          m('div', { class: 'results' }, [
            Results(controller, data.prizes)
          ]),
          featuredWinners(data.winners, controller.game())
        ]) : // Joker
        m('div', { class: 'winning-numbers-element joker-winning-numbers' }, [
          m('div', { class: 'numbers' }, [
            Numbers(controller, data.winningNumbers, 'joker')
          ]),
          m('div', { class: 'results' }, [
            Results(controller, data.prizes, 'joker'),
            featuredWinners(data.winners, controller.jokerGame())
          ])
        ]);

    };

    // Public functions:
    return Drawing;

  });
